import Dialog from "components/dist/atoms/Dialog"
import { LoanViewType } from "src/backend";
import { useFormElementContext } from "src/contexts/form-element-context";
import { useUserLoanViewType } from "src/hooks/loans/use-user-loan-view-type";
import { Loan } from "src/types/loan";

import { FormElementForm } from "../form-element-form"

interface FormElementFormDialogProps {
    loanId: string;
    loanEntities: Loan['loanEntities']
}

export const FormElementFormDialog = (props: FormElementFormDialogProps) => {
    const formElementContext = useFormElementContext();
    const { loanViewType } = useUserLoanViewType();

    return <Dialog
        onOpenChange={(isOpen) => !isOpen && formElementContext.onSetEditFileRequestId(null)}
        open={!!formElementContext.editFileRequestId}>
        <Dialog.Content
            className='w-full min-w-10 w-fit sm:max-w-fit'>
            <FormElementForm
                parentId={loanViewType === 'CONSOLIDATED_LENDER' ? null : formElementContext.activeFolderId}
                onSuccess={() => formElementContext.onSetEditFileRequestId(null)}
                onCancel={() => formElementContext.onSetEditFileRequestId(null)}
                elementId={formElementContext.editFileRequestId}
                loanId={props.loanId}
                loanEntities={props.loanEntities}
            />
        </Dialog.Content>
    </Dialog>
}