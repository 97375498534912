import { CheckedState } from 'components/dist/atoms/Checkbox';
import Fuse from 'fuse.js';
import React, { useReducer } from 'react';
import { AppUserDTO2, Role } from 'src/backend';
import { useLenderEmployees } from 'src/hooks/use-lender-employees';
import { useSearchUsers } from 'src/hooks/use-search-users';
import { useUser } from 'src/hooks/use-user';
import { isRoleABorrower } from "src/utils/user/is-role-a-borrower";

import { teamMemberSelectListActions, teamMemberSelectListInitialState, teamMemberSelectListReducer } from './team-member-select-list.reducer'
import { TeamMemberSelectListAssignedListItem, TeamMemberSelectListProps, TeamMemberSelectListTeamUser } from './team-member-select-list.types'

const options = {
    threshold: 0.1,
    includeScore: true,
    findAllMatches: true,
    keys: ['familyName', 'givenName', 'emailAddress']
};

export const useTeamMemberSelectList = (props: TeamMemberSelectListProps) => {
    const [state, dispatch] = useReducer(teamMemberSelectListReducer, {
        ...teamMemberSelectListInitialState,
        assignedList: props.assignedList || [],
        unassignedChecked: props.unassignedChecked
    })

    const userState = useUser();
    const { employees } = useLenderEmployees({
        lenderId: props.lenderId,
        skip: userState.isBorrower || props.mode === 'LOAN' || !props.lenderId
    });
    const { borrowers } = useSearchUsers({
        skip: userState.isBorrower || props.mode === 'LOAN'
    });
    // get the users from the loan roles
    const loanUsers = props.loanRoles.map(loanRole => ({
        ...loanRole.user,
        role: loanRole.role
    }));
    // dedupe borrowers and employees from users in the loan
    const loanUsersIds = loanUsers.map(user => user.id);
    const companyBorrowers = borrowers.filter(user => !loanUsersIds.includes(user.id) && props.mode === "COMPANY");
    const companyLenders = employees.filter(user => !loanUsersIds.includes(user.id) && props.mode === "COMPANY");
    // dedupe users list in assignedList from users in the loan
    const companyUsersIds = [
        ...companyBorrowers.map(user => user.id),
        ...companyLenders.map(user => user.id)
    ];
    const assignedListFiltered = state.assignedList.filter(assigned => ![...loanUsersIds, ...companyUsersIds].includes(assigned.user.id));
    // add the assigned list to the loan users
    const allUsers = [
        ...loanUsers,
        ...companyBorrowers.map(user => ({
            ...user,
            role: 'BORROWER' as const
        })),
        ...companyLenders.map(user => ({
            ...user,
            role: user.roleDefault
        })),
        ...assignedListFiltered.map(assigned => ({
            ...assigned.user,
            role: assigned.user.roleDefault
        }))
    ].filter((user, index, self) => self.findIndex(({ id }) => id === user.id) === index);

    // do fuzzy search on the loan roles to find the matching users
    const fuse = new Fuse(allUsers, options);

    const searchResult = fuse.search(state.searchQuery);

    const filtered = searchResult.map(result => result.item);

    const filteredLenders = filtered.filter(user => !isRoleABorrower(user.role))

    const filteredBorrowers = filtered.filter(user => isRoleABorrower(user.role));

    // if a user is assigned and does not exist in the loan users, add them to the loan users
    state.assignedList.forEach(assigned => {
        if (!loanUsersIds.includes(assigned.user.id)) {
            const user = allUsers.find(user => user.id === assigned.user.id);
            if (user) {
                loanUsers.push(user);
            }
        }
    });

    const lendingUsers = state.searchQuery
        ? filteredLenders
        : loanUsers.filter(user => !isRoleABorrower(user.role))

    const borrowingUsers = state.searchQuery
        ? filteredBorrowers
        : loanUsers.filter(user => isRoleABorrower(user.role))

    const teams = [];
    // if we have lending roles, add them to the teams
    if (lendingUsers.length > 0 && userState.isLender) {
        teams.push({
            title: userState.isBorrower ? "Lending Team" : "My Team",
            users: lendingUsers.map(user => {
                // find the assigned type for the role
                const assigned = state.assignedList.find(assigned => assigned.user.id === user.id);
                return ({
                    ...user,
                    isLoggedUser: userState.user.id === user.id,
                    assignedType: assigned ? assigned.type : false
                })
            })
        })
    }
    // if we have borrowing roles, add them to the teams
    if (borrowingUsers.length > 0) {
        teams.push({
            title: userState.isLender ? "Borrowing Team" : "My Team",
            users: borrowingUsers.map(user => {
                // find the assigned type for the role
                const assigned = state.assignedList.find(assigned => assigned.user.id === user.id);
                return ({
                    ...user,
                    assignedType: assigned ? assigned.type : false
                })
            })
        })
    }

    // when the assign button is clicked, call the callback with the assigned list
    const onAssignClick = async (users: AppUserDTO2[]) => {
        try {
            dispatch(teamMemberSelectListActions.loading());
            props.onSelect(users, state.unassignedChecked);
        }
        catch (error) {
            console.error(error);
        } finally {
            dispatch(teamMemberSelectListActions.close());
            dispatch(teamMemberSelectListActions.loaded());
        }
    };

    const onUnAssignedCheckChanged = (checked: boolean) => {
        dispatch(teamMemberSelectListActions.setUnassignedChecked(checked));
        const users = state.assignedList.map(assigned => assigned.user);
        props.onSelect(users, checked);
    };
    // when the checkbox changes, update the assigned list
    const onCheckboxChange = (checked: CheckedState, user: TeamMemberSelectListTeamUser) => {
        // first we check if the user is already in the assigned list
        // but only assigned some of form elements
        const alreadyIndeterminateCheck = state.assignedList.some(assigned => assigned.user.id === user.id &&
            assigned.type === 'indeterminate');
        // if checked is true, we add the user to the assigned list
        // and is not already indeterminate
        let ids: TeamMemberSelectListAssignedListItem[] = [];
        if (checked === true && !alreadyIndeterminateCheck) {
            ids = [...state.assignedList, {
                user,
                role: user.role,
                type: true
            }];
            dispatch(teamMemberSelectListActions.setAssignedList(ids));
        } else {
            // otherwise we remove the user from the assigned list
            ids = state.assignedList.filter(assigned => assigned.user.id !== user.id);
            dispatch(teamMemberSelectListActions.setAssignedList(ids));
        }
        onAssignClick(ids.map(assigned => assigned.user));
    };
    // when the search input changes, update the input value
    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        dispatch(teamMemberSelectListActions.setSearchQuery(inputValue));
    };

    const onClearSearchClick = () => {
        dispatch(teamMemberSelectListActions.setSearchQuery(''));
    }

    return {
        onSearchChange,
        onAssignClick,
        onCheckboxChange,
        onClearSearchClick,
        onUnAssignedCheckChanged,
        isUnAssignedChecked: state.unassignedChecked,
        open: state.open,
        teams,
        loggedInUserId: userState.user.id,
        isLender: userState.isLender,
        searchQuery: state.searchQuery,
        loading: state.loading
    } as const;
};