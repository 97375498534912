import { j as e } from "./jsx-runtime-B6kdoens.js";
import { useState as g } from "react";
import "./Accordion-Bzgs65lf.js";
import "./Avatar-95vFNu6x.js";
import { B as c } from "./Badge-C0bj4DFU.js";
import { B as d } from "./Button-ChOUYHf0.js";
import "./DropdownMenu-Hxa3M2LS.js";
import "./Icon-CK-T4e-h.js";
import "./Input-1P9Hc4eP.js";
import { L as x } from "./Label-CI8_Vg74.js";
import { T as i } from "./Text-DyXxCJgw.js";
import "./Tooltip-Dy7rqQaX.js";
import { c as N } from "./utils-CJ9afRe1.js";
import { S as h } from "./Stack-C1p7gIIp.js";
import "./Switch-BgHWRaX7.js";
import "./Toast-DkQ3kscR.js";
import "./Toggle-Cn0qt44_.js";
import { A as j } from "./AlertDialog.component-C0uV4yT1.js";
import { D as y } from "./Drawer.component-CcZv-Ah4.js";
import { R as m } from "./RadioGroup.component-Dr_ohPjn.js";
import { S as f } from "./Separator.component-BNi038DN.js";
import { u as C } from "./useMediaQuery-BLIs2rKV.js";
const Q = (a) => {
  var n, l;
  const [o, u] = g(null), t = C("(min-width: 768px)"), r = t ? j : y;
  return /* @__PURE__ */ e.jsxs(
    r,
    {
      onOpenChange: a.onOpenChange,
      open: a.open,
      children: [
        /* @__PURE__ */ e.jsx(r.Overlay, {}),
        /* @__PURE__ */ e.jsxs(r.Content, { className: "max-w-md", children: [
          /* @__PURE__ */ e.jsxs(i, { className: "px-3 pt-3", children: [
            "Upload",
            " ",
            a.items.length,
            " ",
            "items to ",
            (n = a.loan) == null ? void 0 : n.shortCode,
            " shoebox?"
          ] }),
          /* @__PURE__ */ e.jsx(f, {}),
          /* @__PURE__ */ e.jsx(r.Header, { className: N("px-4", {
            "text-center items-center justify-center": t,
            "text-left": !t
          }), children: /* @__PURE__ */ e.jsx(i, { size: t ? "base" : "sm", children: "You’re not on this loan. Please select the user's shoebox to add items or select yourself to join loan." }) }),
          /* @__PURE__ */ e.jsxs(
            m,
            {
              onValueChange: (s) => u(s),
              className: "px-2 flex flex-col gap-5",
              children: [
                a.me && /* @__PURE__ */ e.jsxs(
                  h,
                  {
                    row: !0,
                    space: "sm",
                    className: "items-center hover:bg-gray-neutral-30 rounded-lg px-3 py-2",
                    children: [
                      /* @__PURE__ */ e.jsx(m.Item, { value: a.me.id, id: a.me.id }),
                      /* @__PURE__ */ e.jsxs(
                        x,
                        {
                          className: "flex-1 gap-1 flex items-center",
                          htmlFor: a.me.id,
                          children: [
                            a.me.givenName,
                            /* @__PURE__ */ e.jsx(i, { size: "sm", variant: "secondary", children: "(Me)" })
                          ]
                        }
                      ),
                      /* @__PURE__ */ e.jsx(c, { variant: "secondary", children: a.me.role })
                    ]
                  }
                ),
                /* @__PURE__ */ e.jsx(i, { size: "sm", className: "px-3", children: "Team" }),
                (l = a.loan) == null ? void 0 : l.users.map((s) => /* @__PURE__ */ e.jsxs(
                  h,
                  {
                    row: !0,
                    space: "sm",
                    className: "items-center hover:bg-gray-neutral-30 rounded-lg px-3 py-2",
                    children: [
                      /* @__PURE__ */ e.jsx(m.Item, { value: s.id, id: s.id }),
                      /* @__PURE__ */ e.jsx(x, { className: "flex-1", htmlFor: s.id, children: s.name }),
                      /* @__PURE__ */ e.jsx(c, { variant: "secondary", children: s.role })
                    ]
                  },
                  s.id
                ))
              ]
            }
          ),
          /* @__PURE__ */ e.jsx(f, {}),
          /* @__PURE__ */ e.jsxs(j.Footer, { children: [
            /* @__PURE__ */ e.jsx(
              d,
              {
                onClick: () => {
                  var s;
                  return (s = a.onOpenChange) == null ? void 0 : s.call(a, !1);
                },
                className: "w-24",
                variant: "outline",
                children: "Cancel"
              }
            ),
            /* @__PURE__ */ e.jsx(
              d,
              {
                className: "w-24",
                onClick: () => {
                  o && a.loan && a.onConfirm(a.loan, o, a.items);
                },
                disabled: !o,
                children: "Add"
              }
            )
          ] })
        ] })
      ]
    }
  );
};
export {
  Q as A
};
